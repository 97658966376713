export default {
  'login.tenant': 'Tenant',
  'login.account': 'Account',
  'login.account.placeholder': 'Mobile or Email',
  'login.password': 'Password',
  'login.code': 'Code',
  'login.btn': 'Login',
  'login.forget': 'Forget',
  'login.copyright': '',
  'login.title': 'Welcome to the Marketing Cloud',
};
